import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import session from "@/modules/session";
import { IonicVue } from '@ionic/vue';
import '@ionic/vue/css/core.css';
import '@ionic/vue/css/normalize.css';
import 'swiper/css';
import '@ionic/vue/css/ionic-swiper.css';
import 'animate.css';
import './registerServiceWorker';
import {loadTranslations} from "@/helpers/translator.js";
import '@/theme/ready2eat/scss/app.scss';
import '@/scss/index.scss';
import store from "@/store/store.js";
import PluginService from "@/services/pluginService.js";

async function initApp() {
  const app = createApp(App)
    .use(IonicVue)
    .use(session)
    .use(store)
    .use(router)

  // Initialize all plugins
  await PluginService.initializeAll(app);

  // Load translations and mount the app
  await loadTranslations().then(() => {
    app.mount('#app');
  });
}

window.initMap = () => {
  console.log('Google Maps API loaded');
};

initApp();
