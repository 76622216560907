<template>
  <AppModal>
    <div class="navigation" :class="{'native-padding': native}">
      <navigation-header
        @close="close"
        @navigate="goTo"
      />
      <div class="nav-main">
        <container>
          <navigation-order-follow-link
            v-if="currentOrder"
            :order="currentOrder"
            @navigate="goTo"
          />
          <navigation-links
            :current-order="currentOrder"
            @navigate="goTo"
          />
          <div class="d-flex">
            <LangSwitcher class="m-auto" />
          </div>
          <div v-if="authenticated" class="d-flex">
            <navigation-logout-button
              @logout="doLogout"
            />
          </div>
        </container>
      </div>
    </div>
  </AppModal>
</template>

<script setup>
  import {onBeforeMount, ref} from 'vue';
  import {useRouter} from 'vue-router';
  import {modalController} from '@ionic/vue';
  import LangSwitcher from '@/modules/global/parts/LangSwitcher.vue';
  import AppModal from '@/modals/AppModal.vue';
  import Container from '@/layout/layout/Container.vue';
  import NavigationLogoutButton from "@/modules/navigation/parts/NavigationLogoutButton.vue";
  import NavigationLinks from "@/modules/navigation/parts/NavigationLinks.vue";
  import NavigationOrderFollowLink from "@/modules/navigation/parts/NavigationOrderFollowLink.vue";
  import NavigationHeader from "@/modules/navigation/parts/NavigationHeader.vue";
  import {$showToast, __t} from "@/globals.js";
  import {userService} from "@/services/app/userService.js";
  import {authService} from "@/services/auth/authService.js";
  import PlatformService from "@/services/PlatformService.js";

  const router = useRouter();

  const currentOrder = ref(null);
  const authenticated = ref(false);
  const native = PlatformService.isNative();

  function close() {
    modalController.dismiss();
  }

  async function doLogout() {
    await authService.logout();

    await close();

    await router.push({name: 'login'});

    $showToast(__t('success.logout'), 'success');
  }

  function goTo(route, params) {
    close();
    router.push({name: route, params});
  }

  onBeforeMount(async () => {
    authenticated.value = await userService.isAuthenticated();
    currentOrder.value = await userService.getCurrentOrder();
  });
</script>

<style lang="scss">
@import "@/scss/smooth";

.navigation {
  display: flex;
  border-radius: 0;
  width: 100%;
  background-color: var(--r2e-background);
  padding-top: var(--padding-m);
  flex-direction: column;

  &.native-padding {
    padding-top: 6rem;
  }

  a:hover {
    cursor: pointer;
  }

  .nav-main {
    padding: var(--padding-m) var(--padding-xs);
    padding-top: 0;

    @media(min-height: 700px) {
      padding: var(--padding-l) var(--padding-xs);
      padding-top: 0;
    }

    .d-flex {
      display: flex;
    }
  }
}

[data-theme='dark'] {
  .navigation {
    background-color: var(--r2e-secondary-600);
  }
}
</style>
