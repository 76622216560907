<template>
  <ion-header />
  <ion-content class="ion-padding content-radius" overflow-scroll="false">
    <div class="inner-content">
      <div class="modal-header">
        <h3>
          <template v-if="deliveryMode === 'delivery'">
            <Icon name="addresses" />
            {{ __t('app.initial-search-modal.title') }}
          </template>
          <template v-else>
            <Icon name="address-establishment" />
            {{ __t('app.initial-search-modal.title.takeaway') }}
          </template>
        </h3>
      </div>
      <div class="modal-main mb-l">
        <div class="modal-search-input">
          <search-address-input
            v-if="loaded"
            id="search-input"
            :auto-submit="false"
            :label="__t('app.initial-search-modal.label')"
            :placeholder="deliveryMode === 'delivery'
              ? __t('app.initial-search-modal.placeholder')
              : __t('app.initial-search-modal.placeholder.takeaway')"
            @can-search="setCanSearch"
            @set-address="setCurrentAddress"
          />
        </div>
        <span v-if="error" class="error-message">{{ __t('error.banner.search') }}</span>
      </div>
    </div>
  </ion-content>
  <ion-footer style="box-shadow: none;">
    <app-button :disabled="!canSearch" :loading="locationUpdated" @click="submitSearch">
      {{ __t('app.modal.submit') }}
    </app-button>

    <DeliveryToggle class="mt-m" />
  </ion-footer>
</template>

<script setup>
  import {nextTick, onMounted, ref} from 'vue';
  import Icon from '@/ui/icon/Icon.vue';
  import SearchAddressInput from '@/modules/global/SearchAddressInput.vue';
  import session from '@/modules/session';
  import {modalController, IonHeader, IonContent, IonFooter} from "@ionic/vue";
  import DeliveryToggle from "@/modules/order/parts/DeliveryToggle.vue";
  import AppButton from "@/ui/button/AppButton.vue";
  import axios from "axios";
  import {apiUrl} from "@/configuration/api.js";
  import {$showToast, __t} from "../globals.js";
  import {userService} from "@/services/app/userService.js";
  import {cartService} from "@/services/app/cartService.js";
  import Address from "@/resources/Address.js";
  import store from "@/store/store.js";

  // Define reactive state
  const address = ref(null);
  const canSearch = ref(false);
  const error = ref(false);
  const loaded = ref(false);
  const deliveryMode = ref('delivery');
  const establishment = ref(null);
  const cartSummary = ref(null);
  const locationUpdated = ref(false);

  // Animate modal on mount
  onMounted(async () => {
    locationUpdated.value = false;

    await nextTick(() => {
      waitForGoogleAPI();
    });

    deliveryMode.value = await cartService.getDeliveryMode();
    establishment.value = await cartService.getEstablishment();
    cartSummary.value = await cartService.getCartSummary();
  });

  const setCurrentAddress = async (val) => {
    console.log('set current address', val);
    address.value = setGoogleAddressToAddressFormat(val);
  };

  // Methods
  const submitSearch = async () => {
    error.value = false;

    if (canSearch.value) {
      console.log('submit search', address.value);
      locationUpdated.value = true;

      const addressObj = new Address(address.value);
      await userService.setUserAddress(addressObj);

      await store.dispatch('updateAddressStatus');

      if(deliveryMode.value === 'delivery' && establishment.value){
        await fetchEstablishment(addressObj);
      }

      $showToast(__t('success.location.updated'), 'success');

      await modalController.dismiss();

    } else {
      locationUpdated.value = false;
      error.value = true;
    }
  };

  const fetchEstablishment = async (address) => {
    const slug = establishment.value.slug;

    await axios.get(apiUrl(
      `customer/establishment/${slug}?latitude=${address.latitude}&longitude=${address.longitude}`)
    ).then(({data}) => {
      session.commit('cart/setMinimumOrderAmount', data.data.minimum_order_amount);
      session.commit('cart/setDeliverPrice', data.data.delivery_price);

      console.log('api delivery cost:', data.data.delivery_price);
      console.log('cart delivery cost:', data.data.delivery_price);
    })
  };

  const setGoogleAddressToAddressFormat = (googleAddress) => {
    const payload = {
      street: googleAddress.route,
      number: googleAddress.street_number,
      postal_code: googleAddress.postal_code,
      city: {
        name: googleAddress.locality,
        zip: googleAddress.postal_code,
        province: googleAddress.administrative_area_level_2,
        country: googleAddress.country,
        country_code: 'BE',
      },
      country: googleAddress.country,
      latitude: googleAddress.latitude,
      longitude: googleAddress.longitude,
    };

    return new Address(payload);
  }

  const setCanSearch = () => {
    canSearch.value = true;
  }

  const waitForGoogleAPI = () => {
    if (window.google) {
      loaded.value = true;
    } else {
      requestAnimationFrame(waitForGoogleAPI);
    }
  };
</script>

<style lang="scss">
.ion-modal-search-initial {
  --height: 36rem;

  .modal-main {
    margin: auto;
    max-width: 38rem;
  }

  .error-message {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 var(--margin-s);
    height: 3rem;
    background-color: var(--r2e-primary-100);
    margin: var(--margin-m) auto;
    font-size: 1rem;
    font-weight: 500;
    color: var(--r2e-error);

    max-width: 36rem;

    @media(min-width: 1080px) {
      max-width: 46rem;
    }
  }

  ion-footer {
    max-width: 38rem;
    box-shadow: none;
    display: flex;
    margin: auto;
    padding: var(--padding-m) 0;
    padding-bottom: var(--padding-l);
    flex-direction: column;
    align-items: center;
  }
}

[data-theme='dark'] {
  .ion-modal-search-initial {
    h3 {
      color: var(--r2e-white);

      svg {
        fill: var(--r2e-white);
      }
    }


    button.r2e-button {
      background-color: var(--r2e-primary);
      color: var(--r2e-white);
    }

    .corner-mask {
      background-color: var(--r2e-secondary) !important;
    }

    button.search {
      background-color: transparent;
    }

  }
}

</style>
