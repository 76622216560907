import * as Sentry from '@sentry/vue';
import Smartlook from 'smartlook-client';
import OneSignal from "onesignal-cordova-plugin";
import { FirebaseCrashlytics } from "@capacitor-firebase/crashlytics";
import { Browser } from "@capacitor/browser";
import { App as CapacitorApp } from '@capacitor/app';
import FirebaseWebConfig from "@/services/firebase/FirebaseWebConfig.js";
import PlatformService from "@/services/PlatformService.js";
import axios from "axios";
import { preferenceService } from "@/services/app/preferenceService.js";
import { userService } from "@/services/app/userService.js";
import { iconService } from "@/services/app/iconService.js";
import router from '@/router';

class PluginService {
  async initializeAll(app) {
    await this.initSentry(app);
    await this.initSmartlook();
    await this.initFirebase();
    await this.initOneSignal();
    await this.setAxiosSettings();
    await this.initIcons();
    await this.initBrowserRedirect();
    await this.initDeepLinks();
    await this.loadGoogleMapsApi();
    await this.setVersion();
  }

  async initSentry(app) {
    Sentry.init({
      dsn: "https://480e53368b2556c0485ba1106c8e0f46@o1294474.ingest.us.sentry.io/4508052938162176",
      release: 'ready2eat@' + import.meta.env.VITE_APP_VERSION,
      dist: '1',
      app: app
    });
  }

  async initSmartlook() {
    if (PlatformService.isWeb() && PlatformService.isProduction()) {
      Smartlook.init(process.env.SMARTLOOK_KEY);
    }
  }

  async initFirebase() {
    if (!PlatformService.isNative()) {
      const firebaseConfig = new FirebaseWebConfig();
      window.firebase_app = firebaseConfig.app();
    } else if (PlatformService.isNative() && PlatformService.isIOS()) {
      await FirebaseCrashlytics.log({
        message: 'App started'
      });
    }
  }

  async initOneSignal() {
    if (PlatformService.isNative()) {
      OneSignal.initialize(process.env.ONESIGNAL_APP_ID);
    }
  }

  async setAxiosSettings() {
    axios.interceptors.request.use(
      async (config) => {
        const tokenData = await userService.getAuthTokenData();
        const platform = PlatformService.getPlatform();
        const version = await preferenceService.getAppVersion();

        if (tokenData?.access_token) {
          config.headers['Authorization'] = 'Bearer ' + tokenData.access_token;
        }

        config.headers['locale'] = localStorage.getItem('locale') ?? 'nl';
        config.headers['Accept-Language'] = localStorage.getItem('locale') ?? 'nl';
        config.headers['X-App-Version'] = version;
        config.headers['X-App-Platform'] = platform;

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  async initIcons() {
    await iconService.initIcons();
  }

  async initBrowserRedirect() {
    if (PlatformService.isNative() && PlatformService.isIOS()) {
      CapacitorApp.addListener('appUrlOpen', (event) => {
        if (event.url && event.url.startsWith('ready2eat://')) {
          Browser.close();
        }
      });
    }
  }

  async initDeepLinks() {
    if (PlatformService.isNative()) {
      CapacitorApp.addListener('appUrlOpen', (event) => {
        if (event.url.includes('ready2eat://app.ready2eat.be/')) {
          const slug = event.url.replace('ready2eat://app.ready2eat.be/', '');
          router.push({ path: slug }).catch((err) => {
            if (err.name !== 'NavigationDuplicated') {
              console.error(err);
            }
          });
        }
      });
    }
  }

  async loadGoogleMapsApi() {
    return new Promise((resolve, reject) => {
      const scriptId = 'google-maps-api-script';
      if (document.getElementById(scriptId)) {
        resolve();
        return;
      }

      const apiKey = process.env.GOOGLE_MAPS_WEB_API_KEY;

      if (!apiKey) {
        reject(new Error('Google Maps API key is missing'));
        return;
      }

      const script = document.createElement('script');
      script.id = scriptId;
      script.async = true;
      script.defer = true;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=initMap`;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error('Failed to load Google Maps API'));
      document.head.appendChild(script);
    });
  }

  async setVersion() {
    if (PlatformService.isNative()) {
      const version = import.meta.env.VITE_APP_VERSION;
      await preferenceService.setAppVersion(version);
    }
  }
}

export default new PluginService();
